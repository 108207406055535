import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function warehousestatementruleUseList() {
  // Use toast
  const toast = useToast()

  const refListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'id', label: 'ID', sortable: true },
    { key: 'company_id', label: '主体ID', sortable: true },
    { key: 'cost_type', label: '费用科目 sales_cost_type', sortable: true },
    { key: 'charge_type', label: '费用科目扣费类型 sales_cost_charge_type', sortable: true },
    { key: 'rule_amount', label: '当扣费类型选择月/年固定值时，表头显示金额，当扣费类型选择按销售额比例时，表头显示百分比 12%填0.12', sortable: true },
    { key: 'is_brand', label: '是否品牌必选', sortable: true },
    { key: 'brand_id', label: '品牌Id', sortable: true },
    { key: 'remark', label: '备注', sortable: true },
    { key: 'attachments', label: '附件', sortable: true },
    { key: 'state', label: '状态', sortable: true },
    { key: 'add_time', label: '创建时间', sortable: true },
    { key: 'creator', label: '创建人', sortable: true },
    { key: 'modify_time', label: '修改时间', sortable: true },
    { key: 'updator', label: '修改人', sortable: true },
        { key: 'actions', label: '操作' },
  ]

  const start = ref(1)
  const limit = ref(10)
  const limitOptions = [10, 25, 50, 100]
  const listTotals = ref(0)
  const searchKey = ref('')
  const orderBy = ref('rule_id')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: limit.value * (start.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (start.value - 1) + localItemsCount,
      of: listTotals.value,
    }
  })

  const refetchData = () => {
	  refListTable.value.refresh()
  }

  watch([start, limit, searchKey], () => {
    refetchData()
  })

  const searchList = (ctx, callback) => {
    store
      .dispatch('warehousestatementrule/search', {
        search_key: searchKey.value,
        start: start.value,
        limit: limit.value,
        order_by: orderBy.value,
        order_desc: isSortDirDesc.value === true ? 'desc':'',
      })
      .then(response => {
        const data = response.data.data
        const list = data.ext.list
        listTotals.value = data.ext.totals

        callback(list)
      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            title: '列表获取错误',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    searchList,
    tableColumns,
    limit,
    start,
    listTotals: listTotals,
    dataMeta,
    limitOptions,
    searchKey,
    orderBy,
    isSortDirDesc,
    refListTable,

    refetchData,

    // Extra Filters
  }
}
